import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import LowRateMicrositeHeader from './../_low-rate-header';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const buttonHref = '/credit-cards/low-rate/addcard/details/';

function AchLandingPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main 
        className="navigation-spacer"
        css={`
          @media (min-width: 992px) {
            section.hero-banner {
              margin-top: 120px;
            }
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/low-rate/addcard/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude Low Rate Mastercard"
          />
          <title>Additional Card Holder Landing | Low Rate Mastercard</title>
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <AchPageHeaderSection />
        )}
        <LowRateMicrositeHeader />

        <Section>
          <Heading4 css={{ color: '#1964B4' }}>
            Share the benefits with an additional cardholder
          </Heading4>

          <div className="row">
            <div className="col-md-8">
              <p>
                Adding an Additional Cardholder on your account is free and can help provide extra security for emergencies or help with your life admin. As the account holder, you're still responsible for making repayments and any outstanding balances on your account.
              </p>
              <p>
                Your additional cardholder can make purchases, request cash advances, update their PIN and report their card as lost or stolen, but they won't be able to register or sign in to your account online via the website and mobile app, or receive any statements. You will also need to activate your card before they can activate theirs.
              </p>
              <p>
                It's a good idea to talk to your additional cardholder about how you'd like the card to be used. With some good habits in place, you can both enjoy the benefits of the Latitude Low Rate Mastercard credit card. Speaking of good habits, set up a direct debit for repayments to avoid late fees if you haven't already.
              </p>
              <p>Before you complete the online form, please have handy:</p>
              <p>
                <ul>
                  <li>
                    Your account number &mdash; this can be found on your
                    statement and your application approval email
                  </li>
                  <li>
                    Additional Cardholder's full name &mdash; as it appears on
                    their ID
                  </li>
                  <li>
                    Additional Cardholder's date of birth &mdash; make sure your
                    nominated additional cardholder is an Australian resident
                    and over 16 years of age
                  </li>
                  <li>
                    Additional Cardholder's email address &mdash; so we can
                    contact them to complete the request
                  </li>
                </ul>
              </p>

              <p>
                Simply complete the <a href={buttonHref}>online form</a> and
                you're one step closer to sharing the benefits of your Latitude Low Rate Mastercard!
              </p>
            </div>
            <div className="col-md-4">
              <Link
                button={BUTTON_STYLE.SECONDARY}
                css={{
                  margin: '0 auto',
                  width: 'fit-content'
                }}
                href={buttonHref}
              >
                Complete Today
              </Link>
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchLandingPage;
